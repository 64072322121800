import { Rive } from '@rive-app/canvas';

document.addEventListener('DOMContentLoaded', () => {
  const riveCanvasLarge = document.getElementById('rive-canvas');
  const riveCanvasSmall = document.getElementById('rive-canvas-small');

  if (!riveCanvasLarge && !riveCanvasSmall) return;

  const variation = $.cookie('_sofatutor_conv_100251594');
  let riveSrc = '';

  if (variation === '1002826615') {
    riveSrc = '/assets/application/welcome/welcome_hero_design/kappu1.riv';
  } else if (variation === '1002826616') {
    riveSrc = '/assets/application/welcome/welcome_hero_design/zazie.riv';
  } else if (variation === '1002826617') {
    riveSrc = '/assets/application/welcome/welcome_hero_design/kappu2.riv';
  } else {
    return;
  }

  if (riveCanvasLarge) {
    const riveInstanceLarge = new Rive({
      src: riveSrc,
      canvas: riveCanvasLarge,
      autoplay: true,
      onLoad: () => {
        riveInstanceLarge.resizeDrawingSurfaceToCanvas();
      }
    });
  }

  if (riveCanvasSmall) {
    const riveInstanceSmall = new Rive({
      src: riveSrc,
      canvas: riveCanvasSmall,
      autoplay: true,
      onLoad: () => {
        riveInstanceSmall.resizeDrawingSurfaceToCanvas();
      }
    });
  }
});
